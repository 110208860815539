import React, { useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getLivabilityProjects,
  nextLivabilityProjects,
  addWishlist,
} from '../../../redux/actions/main';

import { getPropertyHeader } from '../../../redux/actions/propertyDetails';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import ViewAll from '../../Desktop/Common/ViewAll';
import Skeleton from '@mui/material/Skeleton';
import {
  capitalizeFirstLetter,
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import FallbackMobLivability from '../Common/FallbackMobLivability';
import ClassNames from 'classnames';
// import MobHorizontalScroll from '../../packages/mob-horizontal-scroll';
import MobHorizontalScrollNew from '@/components/packages/mob-horizontal-scroll-new';
import useUserInfo from '../../packages/use-user-info';
import useAccessToken from '../../packages/use-access-token';
import MobResidentialProjectCard from '@/components/packages/mob-residential-project-card';
import Variables from 'styles/variables.module.scss';
import classes from './mobLivability.module.scss';
import NextHead from 'next/head';

const highlightedWord = word => {
  return (
    <span
      style={{
        color: Variables.darkorangeColor,
      }}>
      {word}
    </span>
  );
};

// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  sliderbtnClass,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist
) =>
  list &&
  list.length > 0 &&
  list
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          cardIndex={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          module_type={module_type}
          sliderbtnClass={sliderbtnClass}
          cachedProject={cachedProject}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
          toggleWishlist={toggleWishlist}
        />
      );
    });

const MenuItem = ({
  text,
  cardIndex,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  sliderbtnClass,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist,
}) => {
  const {
    pId,
    projectImage,
    projectName,
    weRecommendCount,
    developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName,
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    offer,
    dealOffer,
    furnishingStatus,
    propetyBuyOrRent,
  } = text;
  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();

  // const onScheduleTourClick = (propertyid, property_name) => {
  //   if (open_modal) open_modal(propertyid, property_name, developerName, false,false);
  // };

  return (
    <>
      {/* <Suspense> */}
      <MobResidentialProjectCard
        cardIndex={cardIndex}
        isResale={propetyBuyOrRent === 'resale'}
        maxCarpetArea={maxCarpetArea}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
        cachedProject={cachedProject}
        projectName={projectName}
        livability_index={liveabilityIndexCount}
        investment_index={bestForInvestmentCount}
        locationwithoutCityName={localityName}
        dealOffer={dealOffer}
        offer={offer}
        property_id={pId}
        propertyDetailsClick={propertyDetailsClick}
        propertyDetailsGallery={propertyDetailsGallery}
        property_name={projectName}
        scheduleTourClick={open_modal}
        sliderbtnClass={sliderbtnClass}
        pre
        title={<CommonHeaderText variant="h4" text={projectName} />}
        location={localityName}
        bhk_types={bhk_types}
        details={
          <CommonHeaderText
            variant="h5"
            text={localityName + ', ' + cityName}
          />
        }
        developer_name={`By ` + developerName}
        wishlists={wishlists}
        sqft={
          <CommonHeaderText
            variant="h5"
            text={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
          />
        }
        squarFit={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
        star_rating={weRecommendCount}
        p_we_recommend_count={weRecommendCount}
        bhk={bhk_types && <CommonHeaderText variant="h5" text={bhk_types} />}
        priceTag={
          minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
            <CommonHeaderText
              variant="h4"
              text={
                maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
              }
            />
          ) : (
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                open_modal(pId, projectName, false, true);
              }}>
              <CommonHeaderText
                text={'Price On Request'}
                variant="h4"
                style={{
                  cursor: 'pointer',
                  color: Variables.darkColor,
                  fontSize: '15px',
                  fontWeight: '700',
                }}
              />
            </div>
          )
        }
        Images={projectImage}
        ImageWidth="300px"
        ImageHeight="125px"
        street_url={streetView}
        video_url={videoLink}
        module_type={slugify(module_type)}
        cityName={slugify(cityName)}
        maxPrice={maxPrice}
        minPrice={minPrice}
        priceToshow={
          maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
        }
        toggleWishlist={toggleWishlist}
        minCarpetArea={minCarpetArea}
        furnishingStatus={furnishingStatus}
      />
      {/* </Suspense> */}
    </>
  );
};

const LivabilitySection = props => {
  let { module_type, sliderbtnClass } = props;
  const {
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
  } = props;

  const { projects, loading } =
    props.apiData.projects.length > 0
      ? props.apiData
      : { projects: [], loading: false };
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  module_type = module_type || 'buy';

  const propertyDetailsClick = (property_id, property_name, location) => {
    //  e.stopPropagation()
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: location,
    });
  };
  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    module_type,
    sliderbtnClass,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    toggleWishlist
  );


  const [firstMount, setfirstMount] = useState(false)
  useEffect(() => {
    if (!firstMount) {
      setfirstMount(true)
      return;
    }
    let mounted = false;
    if (!mounted) {
      let userId = user?.id;
      props.getLivabilityProjects({
        city_id: city?.id,
        skip: 0,
        limit: 6,
        module_type: module_type,
        section: 'dealoftheweek',
        user_id: userId,
      });
    }
    return () => {
      mounted = true;
    };
  }, [city, module_type]);

  /* Commenting out 2nd useEffect because above useEffect does the job of fetching data.
When you slide in mobile view then data gets fetched for livability due to 2nd useEffect,
whereas we need data for dealoftheweek */

  // Weekday Logic
  const [weekday, setweekday] = useState();
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  function getWeekBegin() {
    let now = new Date();
    let next = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - now.getDay())
    );
    return next;
  }
  //convert date into required format
  function convert(str) {
    let date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);

    return [day, months[mnth - 1], date.getFullYear()].join(' ');
  }

  useEffect(() => {
    let firstDay = getWeekBegin();
    setweekday(convert(firstDay));
  }, [weekday]);

  let contentLivability;
  if (!projects || projects.length === 0) {
    contentLivability = <FallbackMobLivability />;
  } else {
    contentLivability = <MobHorizontalScrollNew data={firstmenu} />;
  }

  return projects.length !== 0 ? (
    <section
      ref={forwardedRef}
      style={{ marginTop: '68px', backgroundColor: '#FAFAFA' }}
      className="main-section">
        <NextHead>
           <link rel="preload" as="image" href={`${projects[0].projectImage[0]}?tr=w-300,h-125`} />
        </NextHead>
      <Grid
        justifyContent="space-between"
        alignItems="flex-start"
        container
        className="heading-section">
        <Grid className={classes.headingLivability}>
          <CommonHeaderText
            variant="h1"
            text={
              <>
                {highlightedWord('Deal of The Week ')}in{' '}
                {capitalizeFirstLetter(city?.name)}
              </>
            }
            color="#000"
          />
          {!projects || (projects.length === 0 && loading) ? (
            <Skeleton animation="wave" variant="rect" width={200} height={10} />
          ) : (
            <CommonHeaderText
              text={<> Ends on {weekday}</>}
              size="13px"
              color="#000"
            />
          )}
        </Grid>
        <Grid>
          <ViewAll
            text="View All"
            url={getSearchPageURL(
              searchURLType.dealOfWeek,
              module_type,
              city?.name
            )}
          />
        </Grid>
      </Grid>
      <div className={ClassNames(['container', 'card-section'])}>
        {contentLivability}
      </div>
    </section>
  ) : (
    ''
  );
};

const MobLivability = handleViewport(LivabilitySection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.livabilityReducer,
  whishlistApiData: state.addWishlistReducer,
});

const mapDispatchToProps = {
  getLivabilityProjects,
  nextLivabilityProjects,
  getPropertyHeader,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobLivability);
