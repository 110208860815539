import React, { useState, useRef, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import { getDevelopers, nextDevelopers } from '../../redux/actions/main';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../packages/common-header-text';
import MobDeveloperCard from '../packages/mob-developers-card';
import ViewAll from './Common/ViewAll';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { capitalizeFirstLetter, slugify, slugifyURL } from '../util';
import HorizontalScroll from '../packages/horizontal-scroll';
import HorizontalScrollButtons from '../Desktop/Common/HorizontalScrollButtons';
import FallbackCard from '../Desktop/Common/FallbackCard';
import Variables from 'styles/variables.module.scss';
import classes from './DesktopBuilderCardVertical.module.scss';

const readMoreText = (charLimit, developer_desc) => {
  let shortText = '';
  if (developer_desc) {
    shortText =
      developer_desc
        .substr(0, charLimit)
        .replace(/[\s\n]+$/, '')
        // eslint-disable-next-line
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, '') + '...';
  }
  return shortText;
};

const MenuItem = ({ text, selected, classes, forwardedRef }) => {
  const {
    developer_name,
    logo,
    rating,
    id,
    description: developer_desc,
    experience,
    projects_listed,
  } = text;

  const buildersURL = `/builders/${slugify(
    developer_name.replace(/\s/g, '-')
  ).toLowerCase()}`;

  return (<>
    <div ref={forwardedRef}>
      <MobDeveloperCard
        redirectURL={buildersURL}
        selected={selected}
        id={id}
        developer_desc={developer_desc}
        developer_body={
          <Grid container>
            <Grid size={12}>
              <Card className={classes.cardRoot}>
                <CardContent className={classes.cardSubHeader}>
                  <Grid container>
                    <Grid
                      style={{
                        borderLeft: '0px',
                        border: '1px solid #ececec',
                        padding: '12px 0',
                      }}
                      size={6}>
                      <CommonHeaderText
                        text={
                          <span
                            className={classes.expText}
                            // style={{
                            //   fontWeight: '700',
                            //   fontSize: '15px',
                            //   color: 'black',
                            //   fontFamily: 'Open Sans !important',
                            //   paddingRight: '5px',
                            // }}
                          >
                            {experience || 0} yrs Experiences
                          </span>
                        }
                        variant="h4"
                        // style={{
                        //   fontFamily: 'Open Sans !important',
                        //   fontWeight: '400',
                        //   textAlign: 'center',
                        //   fontSize: '14px',
                        //   color: '#838C96',
                        // }}
                      />
                    </Grid>
                    <Grid
                      style={{
                        borderLeft: '1px solid #fff',
                        borderRight: '1px solid #fff',
                        border: '1px solid #ececec',
                        padding: '12px 0',
                      }}
                      size={6}>
                      <CommonHeaderText
                        text={
                          <span
                            className={classes.expText}
                            // style={{
                            //   fontWeight: '700',
                            //   fontSize: '15px',
                            //   color: 'black',
                            //   fontFamily: 'Open Sans !important',
                            //   paddingRight: '5px',
                            // }}
                          >
                            {projects_listed || 0} Projects
                          </span>
                        }
                        variant="h4"
                        style={{
                          fontFamily: 'Open Sans !important',
                          fontWeight: '400',
                          textAlign: 'center',
                          fontSize: '14px',
                          color: '#838C96',
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent className={classes.cardInfo}>
                  <CommonHeaderText
                    text={
                      <div>
                        <p>{readMoreText(120, developer_desc)} </p>
                        <a href={slugifyURL(buildersURL)}>
                          Read more about the Developer
                        </a>
                      </div>
                    }
                    variant="h5"
                    style={{
                      fontWeight: '500',
                      textAlign: 'left',
                      fontSize: '14px',
                      color: Variables.darkColor,
                    }}
                  />
                </CardContent>
                <CardContent className={classes.cardFooter}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: Variables.primaryColor,
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}>
                    <ViewAll text="View all Projects" url={buildersURL} />
                    <a
                      href={slugifyURL(buildersURL)}
                      aria-label="View all Projects">
                      <ArrowRightAltIcon
                        className={classes.arrowBtn}
                        // style={{
                        //   marginLeft: '5px',
                        //   marginTop: '5px',
                        //   color: '#FFFFFF',
                        // }}
                      />
                    </a>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        developer_name={
          <CommonHeaderText
            variant="h4"
            text={developer_name}
            style={{
              fontSize: '20px',
              color: '#cd4b0d',
              fontWeight: '700',
              whiteSpace: 'break-spaces',
              overflow: 'hidden',
              width: '178px',
              textOverflow: 'ellipsis',
            }}
          />
        }
        developer_logo={logo}
        star_rating={rating}
      />
    </div>
  </>);
};

const DevelopersSection = (props = { inViewport: false }) => {
  const { data, forwardedRef, city } = props;

  const [place, setplace] = useState(0);
  const { developers } = props.apiData ?? data;
  const [windowSize] = useState(3);

  const componentRef = useRef();
  // const classes = useStyles();

  const loadPrevProjects = () => {
    setplace(prev => prev - 1);
    componentRef.current.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplace(prev => prev + 1);
    componentRef.current.handleArrowClickRight();
  };

  // All items component
  // Important! add unique key
  const Menu = (list, menuSelected) =>
    list?.map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          selected={menuSelected}
          classes={classes}
          forwardedRef={forwardedRef}
        />
      );
    });

  const [selected] = useState('');
  const firstmenu = Menu(developers, selected);
  const [firstMount, setfirstMount] = useState(false)
  useEffect(() => {
    if (!firstMount) {
      setfirstMount(true)
      return;
    }
    props.getDevelopers({
      skip: 0,
      limit: 6,
      location: city?.name,
    });
  }, [city]);
  // const highlightedWordOrange = word => {
  //   return (
  //     <span style={{ color: Variables.darkorangeColor, fontWeight: 700 }}>
  //       {word}
  //     </span>
  //   );
  // };
  // const highlightedWordBlue = word => {
  //   return <span style={{ color: '#0158b5', fontWeight: 700 }}>{word}</span>;
  // };
  const cityUrl = city && city?.name ? city?.name.toLowerCase() : 'mumbai'; // Default to 'mumbai' if city.name is undefined

  return developers?.length > 0 ? (
    <section
      className="main-section1"
      ref={forwardedRef}
      style={{
        padding: '0px',
        marginTop: '50px',
        marginBottom:'48px',
      }}>
      <div className="container">
        <Grid container>
          <Grid
            className={classes.headingText}
            justifyContent="flex-start"
            alignItems="center"
            container
            size={9}>
            <CommonHeaderText
              text={
                <>{'Top Builders in ' + capitalizeFirstLetter(city?.name)}</>
              }
              variant="h2"
            />
            <CommonHeaderText
              text={
                <>
                  Most Credible Developers in {city?.name}
                  {/* {highlightedWordOrange('Developers')}{' '}
                  {highlightedWordBlue('in')}{' '}
                  {highlightedWordOrange(city?.name)} */}
                </>
              }
            />
          </Grid>
          {developers && developers.length > 3 && (
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginLeft: '-8px',
              }}
              size={3}>
              <div
                style={{
                  paddingRight: '15px',
                  borderRight: '2px solid #17171721',
                }}>
                <ViewAll
                  text="View All"
                  url={`/builders-in-${cityUrl || 'mumbai'}`}
                />
              </div>
              <div
                style={{
                  paddingLeft: '15px',
                }}>
                <HorizontalScrollButtons
                  loadPrevProjects={loadPrevProjects}
                  loadNextProjects={loadNextProjects}
                  disableNext={developers.length < (place + 2) * windowSize}
                  disablePrev={place === 0}
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Grid container className="card-section" style={{ marginTop:'-1px'}}>
          <Grid size={12}>
            {developers && developers.length === 0 && (
              <Grid
                spacing={2}
                container
                justifyContent="center"
                alignItems="center">
                <Grid size={4}>
                  <FallbackCard />
                </Grid>
                <Grid size={4}>
                  <FallbackCard />
                </Grid>
                <Grid size={4}>
                  <FallbackCard />
                </Grid>
              </Grid>
            )}

            {developers && developers.length === 2 ? (
              <HorizontalScroll
                itemClass="blog-card-item "
                componentRef={componentRef}
                data={firstmenu}
                dragging={false}
              />
            ) : (
              <HorizontalScroll
                itemClass="blog-card-item "
                componentRef={componentRef}
                data={firstmenu}
                dragging={false}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </section>
  ) : (
    ''
  );
};

const Developers = handleViewport(DevelopersSection, { rootMargin: '-1.0px' });

const mapStateToProps = state => ({
  apiData: state.developersReducer,
  headersApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getDevelopers,
  nextDevelopers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Developers);
